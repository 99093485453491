import { api, omitBlankEntries } from './api'

type Call = any

type CallPerson = {
  id: number
  name: 'Должник' | 'Третье лицо' | 'Неизвестно'
}

type Group = {
  id: number
  name: string
  created_at: string
}

type CallReason = {
  id: string
  name: string
}

type CallResult = {
  id: string
  name: string
}

export const {
  useGetCallsQuery,
  useGetCallPersonsQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useDeleteSegmentMutation,
  useGetCallReasonsByProfileIdQuery,
  useGetCallResulstsByProfileIdQuery,
  useAddCallResultMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<Call[], string>({
      query: (id) => `crm/v1/calling/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Calls', id }]
    }),
    getCallPersons: builder.query<CallPerson[], void>({
      query: () => `crm/v1/calling/person`,
      providesTags: ['CallPerson']
    }),
    getGroups: builder.query<Group[], string>({
      query: () => `crm/v1/calling/groups`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Groups', id }) as const),
        { type: 'Groups' as const, id: 'LIST' }
      ]
    }),
    getCallReasonsByProfileId: builder.query<CallReason[], void>({
      query: () => ({
        url: `crm/v1/calling/reason`,
        // TODO: how tf is this supposed to work?
        params: { person_id: 0 }
      }),
      providesTags: ['CallReason']
    }),
    getCallResulstsByProfileId: builder.query<CallResult[], void>({
      query: () => ({
        url: `crm/v1/calling/result`,
        // TODO: how tf is this supposed to work?
        params: { person_id: 0 }
      }),
      providesTags: ['CallResult']
    }),
    addCallResult: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/call-result`,
        method: 'POST',
        body: omitBlankEntries(body)
      })
    }),
    updateGroup: builder.mutation<
      void,
      Pick<Group, 'id'> & { user_ids: string[]; new_name: string }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/groups/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Groups', id: request.id }
      ]
    }),
    deleteSegment: builder.mutation<any, string | null>({
      query: (id) => ({
        url: `crm/v1/calling/stages/${id}`,
        method: 'DELETE'
      })
    })
  })
})
