import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Navdata = () => {
  //state data
  const { userInfo } = useSelector((state) => state.Login)

  const [isAdmin, setIsAdmin] = useState(false)
  const [isCollection, setIsCollection] = useState(false)
  const [isSegments, setIsSegments] = useState(false)
  const [isQueues, setIsQueues] = useState(false)
  const [isSupervisor, setIsSupervisor] = useState(false)
  const [isDebts, setIsDebts] = useState(false)
  const [isDebtsQueue, setIsDebtsQueue] = useState(false)
  const [isCollectors, setIsCollectors] = useState(false)
  const [isGroups, setIsGroups] = useState(false)

  const [isProducts, setIsProducts] = useState(false)
  const [isPromocodes, setIsPromocodes] = useState(false)
  const [isPromocodesGroups, setIsPromocodesGroups] = useState(false)
  const [isHolidays, setIsHolidays] = useState(false)

  const [isProductExtensions, setIsProductExtensions] = useState(false)
  const [isExternalServicesSettings, setIsExternalServicesSettings] =
    useState(false)
  const [isDocumentTypes, setIsDocumentTypes] = useState(false)
  const [isNotificationsTemplates, setIsNotificationsTemplates] =
    useState(false)
  const [isNotificationsMappingTemplates, setIsNotificationsMappingTemplates] =
    useState(false)
  const [isNotificationsSenders, setIsNotificationsSenders] = useState(false)
  const [isCommunicationTemplates, setIsCommunicationTemplates] =
    useState(false)

  const [isProfileSettings, setIsProfileSettings] = useState(false)
  const [isProfiles, setIsProfiles] = useState(false)
  const [isUsers, setIsUsers] = useState(false)
  const [isDialogues, setIsDialogues] = useState(false)
  const [isClients, setIsClients] = useState(false)
  const [isApps, setIsApps] = useState(false)
  const [isLoans, setIsLoans] = useState(false)
  const [isClaims, setIsClaims] = useState(false)
  const [isPromotions, setIsPromotions] = useState(false)
  const [isPayments, setIsPayments] = useState(false)
  const [isChats, setIsChats] = useState(false)
  const [isCompanies, setIsCompanies] = useState(false)

  const [iscurrentState, setIscurrentState] = useState('Products')

  // const [isRosFinMonitoring, setIsRosFinMonitoring] = useState(false)

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        let id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Admin') {
      setIsAdmin(false)
    }
    if (iscurrentState !== 'Collection') {
      setIsCollection(false)
    }
    if (iscurrentState !== 'ProfileSettings') {
      setIsProfileSettings(false)
    }
    if (iscurrentState !== 'Profiles') {
      setIsProfiles(false)
    }
    if (iscurrentState !== 'Users') {
      setIsUsers(false)
    }
    if (iscurrentState !== 'Dialogues') {
      setIsDialogues(false)
    }
    if (iscurrentState !== 'Clients') {
      setIsClients(false)
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false)
    }
    if (iscurrentState !== 'Loans') {
      setIsLoans(false)
    }
    if (iscurrentState !== 'Claims') {
      setIsClaims(false)
    }
    if (iscurrentState !== 'Promotions') {
      setIsPromotions(false)
    }
    if (iscurrentState !== 'Payments') {
      setIsPayments(false)
    }
    if (iscurrentState !== 'Chats') {
      setIsChats(false)
    }
    if (iscurrentState !== 'Companies') {
      setIsCompanies(false)
    }
  }, [
    iscurrentState,
    isAdmin,
    isCollection,
    isProfiles,
    isUsers,
    isDialogues,
    isProfileSettings,
    isClients,
    isApps,
    isLoans,
    isPayments,
    isClaims,
    isPromotions,
    isCompanies
  ])

  const claimsItem = [
    {
      id: 'my-claims',
      label: 'My claims',
      link: '/my-claims',
      parentId: 'claims'
    },
    {
      id: 'topic-setting',
      label: 'Topic setting',
      link: '/topic-setting',
      parentId: 'claims'
    },
    {
      id: 'claim-templates',
      label: 'Templates claim',
      link: '/claim-templates',
      parentId: 'claims'
    },
    {
      id: 'insolvent',
      label: 'Insolvent',
      icon: 'ri-aliens-line',
      link: '/insolvent',
      parentId: 'collection'
    }
  ]

  userInfo?.roles.includes('head_of_comunications') &&
    claimsItem.push(
      ...[
        {
          id: 'claims-queue',
          label: 'Queue claims',
          link: '/claims-queue',
          parentId: 'claims'
        },
        {
          id: 'claims-report',
          label: 'Report on appeals',
          link: '/claims-report',
          parentId: 'claims'
        }
      ]
    )

  const collectionItems = [
    {
      id: 'external-agencies',
      label: 'External Agencies',
      link: '/external-agencies',
      parentId: 'collection'
    },
    {
      id: 'collection-debts',
      label: 'Debts',
      icon: 'ri-aliens-line',
      link: '/debts',
      parentId: 'collection',
      click: function (e) {
        e.preventDefault()
        setIsDebts(!isDebts)
      },
      stateVariables: isDebts
    },
    {
      id: 'collection-debts-queue',
      label: 'Debts queue',
      icon: 'ri-aliens-line',
      link: '/debts-queue',
      parentId: 'collection',
      click: function (e) {
        e.preventDefault()
        setIsDebtsQueue(!isDebtsQueue)
      },
      stateVariables: isDebtsQueue
    },
    {
      id: 'collection-supervisor',
      label: 'Supervisor',
      icon: 'bx bx-pencil',
      link: '/supervisor',
      parentId: 'collection',
      click: function (e) {
        e.preventDefault()
        setIsSupervisor(!isSupervisor)
      },
      //isChildItem: true,
      stateVariables: isSupervisor
      /*childItems: [
            { id: 1, label: "View all", link: "/queues", parentId: "collection-queues" },
            { id: 2, label: "Create queue", link: "/queues-create", parentId: "collection-queues" },
            { id: 3, label: "View queue", link: "/queues-view", parentId: "collection-queues", hidden: true },
            { id: 4, label: "Edit queue", link: "/queues-edit", parentId: "collection-queues", hidden: true },
        ]*/
    }
  ]

  const headOfCollection = userInfo?.roles.includes('head_of_collection')
    ? [
        {
          id: 'collection-rec-payments',
          link: '/rec-payments',
          icon: '',
          parentId: 'collection',
          label: 'recurring_payments'
        },
        {
          id: 'collection-segments',
          label: 'Segments',
          icon: 'bx bx-pencil',
          link: '/segments',
          parentId: 'collection',
          click: function (e) {
            e.preventDefault()
            setIsSegments(!isSegments)
          },
          isChildItem: true,
          stateVariables: isSegments,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/segments',
              parentId: 'collection'
            },
            // { id: 2, label: "Create segment", link: "/segments-create", parentId: "collection" },
            {
              id: 3,
              label: 'View segment',
              link: '/segments-view',
              parentId: 'collection',
              hidden: true
            }
            // { id: 4, label: "Edit segment", link: "/segments-edit", parentId: "collection", hidden: true },
          ]
        },
        {
          id: 'collectors',
          label: 'Collectors',
          icon: 'ri-aliens-line',
          link: '/collectors',
          parentId: 'collection',
          click: function (e) {
            e.preventDefault()
            setIsCollectors(!isCollectors)
          },
          //isChildItem: true,
          stateVariables: isCollectors
        },
        {
          id: 'groups',
          label: 'Groups',
          icon: 'ri-aliens-line',
          link: '/groups',
          parentId: 'collection',
          click: function (e) {
            e.preventDefault()
            setIsGroups(!isGroups)
          },
          stateVariables: isGroups
        },
        {
          id: 'collection-queues',
          label: 'Queues',
          icon: 'bx bx-pencil',
          link: '/queues',
          parentId: 'collection',
          click: function (e) {
            e.preventDefault()
            setIsQueues(!isQueues)
          },
          isChildItem: true,
          stateVariables: isQueues,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/queues',
              parentId: 'collection-queues'
            },
            {
              id: 2,
              label: 'Create queue',
              link: '/queues-create',
              parentId: 'collection-queues'
            },
            {
              id: 3,
              label: 'View queue',
              link: '/queues-view',
              parentId: 'collection-queues',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit queue',
              link: '/queues-edit',
              parentId: 'collection-queues',
              hidden: true
            }
          ]
        },
        {
          id: 'collection-sell',
          label: 'Debts Sell',
          icon: 'bx bx-pencil',
          link: '/debts-sell',
          parentId: 'collection'
        }
      ]
    : []

  collectionItems.push(...headOfCollection)

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'payments',
      label: 'Payments',
      icon: 'las la-money-bill-wave',
      link: '/#',
      stateVariables: isPayments,
      click: function (e) {
        e.preventDefault()
        setIsPayments(!isPayments)
        setIscurrentState('Payments')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'payments-all',
          label: 'View all',
          link: '/payments',
          parentId: 'payments'
        },
        {
          id: 'payment-view',
          link: '/payment-view',
          parentId: 'payments',
          label: 'View',
          hidden: true
        }
      ]
    },
    {
      id: 'loans',
      label: 'Loans',
      icon: 'ri-pie-chart-line',
      link: '/#',
      stateVariables: isLoans,
      click: function (e) {
        e.preventDefault()
        setIsLoans(!isLoans)
        setIscurrentState('Loans')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'loans-all',
          label: 'View all',
          link: '/loans',
          parentId: 'loans'
        },
        {
          id: 'loan-holidays',
          label: 'Holidays',
          link: '/loan-holidays',
          parentId: 'loans'
        },
        {
          id: 'loan-view',
          link: '/loan-view',
          parentId: 'loans',
          label: 'View',
          hidden: true
        }
      ]
    },
    {
      id: 'applications',
      label: 'Applications',
      icon: 'ri-file-edit-line',
      link: '/#',
      stateVariables: isApps,
      click: function (e) {
        e.preventDefault()
        setIsApps(!isApps)
        setIscurrentState('Apps')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'applications-all',
          label: 'View all',
          link: '/applications',
          parentId: 'applications'
        },
        {
          id: 'application-view',
          link: '/application-view',
          parentId: 'applications',
          label: 'View',
          hidden: true
        }
      ]
    },
    {
      id: 'clients',
      label: 'Clients',
      icon: 'ri-user-3-line',
      link: '/#',
      stateVariables: isClients,
      click: function (e) {
        e.preventDefault()
        setIsClients(!isClients)
        setIscurrentState('Clients')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'clients-all',
          label: 'View all',
          icon: 'bx bx-pencil',
          link: '/clients',
          parentId: 'clients'
        },
        {
          id: 'clients-tags',
          label: 'Tags',
          link: '/add-tags',
          parentId: 'clients'
        },
        {
          id: 'client-view',
          link: '/client-view',
          parentId: 'clients',
          label: 'View',
          hidden: true
        },
        {
          id: 'client-edit',
          link: '/client-edit',
          parentId: 'clients',
          label: 'Edit',
          hidden: true
        }
      ]
    },
    {
      id: 'profiles',
      label: 'Profiles',
      icon: 'ri-account-circle-line',
      link: '/#',
      stateVariables: isProfiles,
      click: function (e) {
        e.preventDefault()
        setIsProfiles(!isProfiles)
        setIscurrentState('Profiles')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'profiles-all',
          label: 'View all',
          icon: ' ri-contacts-book-2-line',
          link: '/profiles',
          parentId: 'profiles',
          click: function (e) {
            e.preventDefault()
            setIsProfiles(!isProfiles)
          }
        },
        {
          id: 'profile-view',
          link: '/profile-view',
          parentId: 'profiles',
          label: 'View',
          hidden: true
        }
      ]
    },

    {
      id: 'profile-settings',
      label: 'Settings',
      icon: 'las la-sliders-h',
      link: '/#',
      stateVariables: isProfileSettings,
      click: function (e) {
        e.preventDefault()
        setIsProfileSettings(!isProfileSettings)
        setIscurrentState('ProfileSettings')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'profile-settings-formatting',
          label: 'Formatting data',
          icon: 'ri-percent-line',
          link: '/settings',
          parentId: 'profile-settings',
          click: function (e) {
            e.preventDefault()
            setIsProfileSettings(!isProfileSettings)
          }
        }
      ]
    },
    {
      id: 'collection',
      label: 'Collections',
      icon: 'mdi mdi-account-cash-outline',
      link: '/#',
      stateVariables: isCollection,
      click: function (e) {
        e.preventDefault()
        setIsCollection(!isCollection)
        setIscurrentState('Collection')
        updateIconSidebar(e)
      },
      subItems: collectionItems
    },
    {
      id: 'admin',
      label: 'Admin',
      icon: 'ri-settings-3-fill',
      link: '/#',
      stateVariables: isAdmin,
      click: function (e) {
        e.preventDefault()
        setIsAdmin(!isAdmin)
        setIscurrentState('Admin')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'admin-promocodes',
          label: 'Promocodes',
          icon: 'bx bx-pencil',
          link: '/promocodes',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsPromocodes(!isPromocodes)
          },
          isChildItem: true,
          stateVariables: isPromocodes,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/promocodes',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Create promocode',
              link: '/promocodes-create',
              parentId: 'admin'
            },
            {
              id: 3,
              label: 'View promocode',
              link: '/promocodes-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit promocode',
              link: '/promocodes-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-promocodes-groups',
          label: 'Группы промокодов',
          icon: 'bx bx-pencil',
          link: '/promocodes-groups',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsPromocodesGroups(!isPromocodesGroups)
          },
          isChildItem: true,
          stateVariables: isPromocodesGroups,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/promocodes-groups',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Создать новую группу промокодов',
              link: '/promocodes-groups-create',
              parentId: 'admin'
            }
          ]
        },
        {
          id: 'admin-holidays',
          label: 'Типы кредитных каникул',
          icon: 'bx bx-pencil',
          link: '/holidays_types',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsHolidays(!isHolidays)
          },
          isChildItem: true,
          stateVariables: isHolidays,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/holidays',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Создать тип КК',
              link: '/holidays-create',
              parentId: 'admin'
            }
          ]
        },
        {
          id: 'admin-products',
          label: 'Products',
          icon: 'bx bx-pencil',
          link: '/products',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsProducts(!isProducts)
          },
          isChildItem: true,
          stateVariables: isProducts,
          childItems: [
            { id: 1, label: 'View all', link: '/products', parentId: 'admin' },
            {
              id: 2,
              label: 'Create product',
              link: '/product-create',
              parentId: 'admin'
            },
            {
              id: 3,
              label: 'View Product',
              link: '/product-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit Product',
              link: '/product-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'roles',
          link: '/roles',
          icon: ' ri-contacts-book-2-line',
          parentId: 'profiles',
          label: 'Roles'
        },
        {
          id: 'users',
          label: 'Users',
          link: '/users',
          parentId: 'admin',
          icon: ' ri-contacts-book-2-line',
          stateVariables: isUsers,
          click: function (e) {
            e.preventDefault()
            setIsUsers(!isUsers)
            setIscurrentState('Users')
            updateIconSidebar(e)
          }
        },
        {
          id: 'admin-tags',
          link: '/mass-add-tags',
          icon: '',
          parentId: 'admin',
          label: 'Tags'
        },
        {
          id: 'admin-product-extensions',
          label: 'extensions_settings',
          icon: 'bx bx-pencil',
          link: '/product-extensions',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsProductExtensions(!isProductExtensions)
          },
          isChildItem: true,
          stateVariables: isProductExtensions,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/product-extensions',
              parentId: 'admin'
            },
            //{ id: 2, label: "Create Product", link: "/product-create", parentId: "admin" },
            {
              id: 2,
              label: 'View Extension',
              link: '/product-extensions-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 3,
              label: 'Edit Extension',
              link: '/product-extensions-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-external-services-settings',
          label: 'External_services_settings',
          link: '/external-services-settings',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsExternalServicesSettings(!isExternalServicesSettings)
          },
          isChildItem: true,
          stateVariables: isExternalServicesSettings,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/external-services-settings',
              parentId: 'admin'
            },
            //{ id: 2, label: "Create", link: "/external-services-settings-create", parentId: "admin" },
            {
              id: 3,
              label: 'View',
              link: '/external-services-settings-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit',
              link: '/external-services-settings-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-document-types',
          label: 'Document_types',
          link: '/document-types',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsDocumentTypes(!isDocumentTypes)
          },
          isChildItem: true,
          stateVariables: isDocumentTypes,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/document-types',
              parentId: 'admin'
            }
            //{ id: 2, label: "Create", link: "/document-types-create", parentId: "admin" },
            //{ id: 3, label: "View", link: "/document-types-view", parentId: "admin", hidden: true },
            //{ id: 4, label: "Edit", link: "/document-types-edit", parentId: "admin", hidden: true },
          ]
        },
        {
          id: 'admin-notifications-templates',
          label: 'Notifications_templates',
          link: '/notifications-templates',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsNotificationsTemplates(!isNotificationsTemplates)
          },
          isChildItem: true,
          stateVariables: isNotificationsTemplates,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/notifications-templates',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Create',
              link: '/notifications-templates-create',
              parentId: 'admin'
            },
            {
              id: 3,
              label: 'View',
              link: '/notifications-templates-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit',
              link: '/notifications-templates-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-notifications-mapping-templates',
          label: 'Notifications_mapping_templates',
          link: '/notifications-mapping-templates',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsNotificationsMappingTemplates(!isNotificationsMappingTemplates)
          },
          isChildItem: true,
          stateVariables: isNotificationsMappingTemplates,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/notifications-mapping-templates',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Create',
              link: '/notifications-mapping-templates-create',
              parentId: 'admin'
            },
            {
              id: 3,
              label: 'View',
              link: '/notifications-mapping-templates-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit',
              link: '/notifications-mapping-templates-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-notifications-senders',
          label: 'Notifications_senders',
          link: '/notifications-senders',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsNotificationsSenders(!isNotificationsSenders)
          },
          isChildItem: true,
          stateVariables: isNotificationsSenders,
          childItems: [
            {
              id: 1,
              label: 'View all',
              link: '/notifications-senders',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'Create',
              link: '/notifications-senders-create',
              parentId: 'admin'
            },
            {
              id: 3,
              label: 'View',
              link: '/notifications-senders-view',
              parentId: 'admin',
              hidden: true
            },
            {
              id: 4,
              label: 'Edit',
              link: '/notifications-senders-edit',
              parentId: 'admin',
              hidden: true
            }
          ]
        },
        {
          id: 'admin-ros-fin-monitoring',
          label: 'Rosfinmonitoring',
          link: '/ros-fin-monitoring',
          parentId: 'admin'
        },
        {
          id: 'admin-strategy',
          label: 'Communications',
          link: '/strategy',
          parentId: 'admin',
          click: function (e) {
            e.preventDefault()
            setIsCommunicationTemplates(!isCommunicationTemplates)
          },
          stateVariables: isCommunicationTemplates,
          isChildItem: true,
          childItems: [
            {
              id: 1,
              label: 'strategies',
              link: '/strategy',
              parentId: 'admin'
            },
            {
              id: 2,
              label: 'templates',
              link: '/strategy/templates',
              parentId: 'admin'
            }
          ]
        },
        {
          id: 'bki-sent-file',
          label: 'Viewing files in BKI',
          link: '/bki-sent-file',
          parentId: 'admin'
        },
        {
          id: 'writeoff-statuses',
          label: 'statuses',
          link: '/writeoff-statuses',
          parentId: 'admin'
        }

        /*{
                id: "admin-product-create",
                label: "Create Product",
                icon: "bx bx-pencil",
                link: "/product-create",
                parentId: 'admin',
                },
              },
              {
                id: "admin-product-view",
                label: "View Product",
                icon: "bx bx-pencil",
                link: "/product-view",
                parentId: 'admin',
                hidden: true,
              },
              {
                id: "admin-product-edit",
                label: "Edit Product",
                icon: "bx bx-pencil",
                link: "/product-edit",
                parentId: 'admin',
                hidden: true,
              }*/
      ]
    },
    {
      id: 'claims',
      label: 'Claims',
      icon: 'mdi mdi-message-badge-outline',
      link: '/#',
      stateVariables: isClaims,
      click: function (e) {
        e.preventDefault()
        setIsClaims(!isClaims)
        setIscurrentState('Claims')
        updateIconSidebar(e)
      },
      subItems: claimsItem
    },
    {
      id: 'promotions',
      label: 'Promotions',
      icon: 'bx bxs-offer',
      link: '/#',
      stateVariables: isPromotions,
      click: function (e) {
        e.preventDefault()
        setIsPromotions((prev) => !prev)
        setIscurrentState('Promotions')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 1,
          label: 'View all',
          link: '/promotions'
        }
      ]
    },
    {
      id: 'chats',
      label: 'Chats',
      icon: 'bx bxs-chat',
      link: '/#',
      stateVariables: isChats,
      click: function (e) {
        e.preventDefault()
        setIsChats((prev) => !prev)
        setIscurrentState('Chats')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 1,
          label: 'My chats',
          link: '/chats/my'
        },
        {
          id: 2,
          label: 'Chat templates',
          link: '/chats/templates'
        },
        {
          id: 3,
          label: 'Chat topics',
          link: '/chats/topics'
        },
        {
          id: 4,
          label: 'Chat global settings',
          link: '/chats/settings/global'
        },
        {
          id: 5,
          label: 'Chat user settings',
          link: '/chats/settings'
        }
      ]
    },
    {
      id: 'companies',
      label: 'Companies',
      icon: 'ri-bank-line',
      link: '/#',
      stateVariables: isCompanies,
      click: function (e) {
        e.preventDefault()
        setIsCompanies(!isCompanies)
        setIscurrentState('Companies')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'companies',
          label: 'Companies',
          link: '/companies'
        },
        {
          id: 'company-edit',
          label: 'Add company',
          link: '/company-edit'
        }
      ]
    }
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
