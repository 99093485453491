import {
  createApi,
  fetchBaseQuery,
  QueryDefinition
} from '@reduxjs/toolkit/query/react'
import { SERVER } from 'src/client'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER,
  credentials: 'include',
  redirect: 'manual'
})

export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   *
   * ATTENTION: if list entity type does not satisfy single entity type
   * e.g. if GET loans/ return [{id: number, name: string}] type
   * and loans/1 return {id: number, name: string, created_at: string} type
   * then two tags must be created Loans and LoanItems because these two are in fact different
   */
  // refetchOnReconnect: true,
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange: true,
  tagTypes: [
    'Calls',
    'CallResult',
    'CallPerson',
    'CallReason',
    'Chats',
    'ChatTopics',
    'ChatTemplates',
    'ChatGlobalSettings',
    'ChatUserSettings',
    'Communications',
    'CommunicationItems',
    'CommunicationRecords',
    'Claims',
    'Clients',
    'ClientSignedDocument',
    'ClientTags',
    'Groups',
    'MessageItems',
    'Phones',
    'Products',
    'Promocodes',
    'Promotions',
    'Profiles',
    'ProfileNotification',
    'Loans',
    'WriteOffTypes',
    'LoanPayments',
    'LoanOperations',
    'LoanCards',
    'LoanSignedDocuments',
    'LoanPurchasers',
    'LoanPromotions',
    'LoanItems',
    'LoanTags',
    'UserItem',
    'User',
    'UserPermission',
    'Holidays',
    'Topic',
    'Collectors',
    'Roles',
    'PersonExtremists',
    'UploadFile',
    'UploadAttachment',
    'EmailMessages',
    'PreviewDocx',
    'TemplatesEmail',
    'TemplatesClaim',
    'ReportClaims',
    'ValidationRosFin',
    'Chargeback',
    'BkiSentFile',
    'ClaimManager',
    'Companies'
  ],
  endpoints: () => ({})
})

export const opaqueRedirectMiddleware: Middleware =
  () => (next) => (action) => {
    if (
      isRejectedWithValue(action) &&
      action?.meta?.baseQueryMeta?.response?.status === 0 &&
      action.meta.baseQueryMeta.response.type === 'opaqueredirect'
    ) {
      window.open(`${SERVER}/crm/v1/keycloak/redirect`, '_self')
    }

    return next(action)
  }

/**
 * Removes all entries which values are empty strings
 * @param o {Object}
 * @returns Returns a new object without modifying the original
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const omitBlankEntries = <T extends {}>(
  o: T | undefined | null | void
) => {
  if (typeof o !== 'object' || Array.isArray(o) || o == null) return {}
  return Object.fromEntries(Object.entries(o).filter(([_, v]) => v !== ''))
}

export type UseQueryHookArgs<Q> = Q extends UseQuery<
  QueryDefinition<infer QA, any, any, any>
>
  ? QA
  : never

export type UseQueryHookResultData<Q> = Q extends UseQuery<
  QueryDefinition<any, any, any, infer R>
>
  ? R
  : never

export type PartialExceptTheseRequired<T, K extends keyof T> = Pick<
  Required<T>,
  K
> &
  Partial<T>
